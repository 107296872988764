import React from 'react';
import Helmet from './helmet';
//import CovidBanner from './covid-banner';
import Navbar from './navbar';
import HeaderCallBack from './header-call-back-form';



const HomeHeaderV2 = (props) => {
  // Full space banner split in two, form on left, strap line on the right
  // Triple split CTA area underneath.
  let bannerTitle = ""
  if (props.pageTitle == null) {
    bannerTitle = "Here to help you"
  } else {
    bannerTitle = props.pageTitle;
  }

  return (
    <div>
      <Helmet />
      <section className="hero imageBg is-fullheight-with-navbar outer-home-hero">
        <div className="sticky-container">

          <Navbar />
        </div>
        <div className="hero-body home-hero">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds">
                <div className="banner-text-home-left">
                  <span>EFFECTIVE, PROFESSIONAL HELP & ADVICE</span>
                  <h1>{bannerTitle}</h1>
                  <p>Whatever your circumstances our experts<br />
                    are here to provide you the best advice and guidance.</p>

                </div>

              </div>

              <div className="column desktop-call-back">
                <HeaderCallBack />
              </div>

            </div>
          </div>
        </div>
      </section>
      <div className="mobile-call-back">
        <HeaderCallBack />
      </div>

    </div>

  )
}


export default HomeHeaderV2;