import React from 'react'
import Helmet from '../components/helmet'
import HomeHeaderV2 from '../components/home-header-v2'
import Footer from '../components/footer'
import ContactForm from '../components/contact-form'

const ContactPage = () => (
    <div>
        <Helmet />
        <HomeHeaderV2 pageTitle="Contact Us" />
        <ContactForm />
        <Footer />
    </div>
)

export default ContactPage