import React from 'react';
// import './style.scss';

import {
    FaUser,
    FaEnvelope,
    FaPhone
} from 'react-icons/fa';



const ContactForm = () => (
    <section className="section">


        <div className="container content">
            <div className="columns">
                <div className="column">
                    <div className="content">

                        <h1>If you think your business needs help, don’t delay in contacting us. We are here to help.</h1>
                        <p>For free initial advice on all of your business recovery solutions you can speak to our team of experienced insolvency advisers on <a href="tel:0800 066 3122">0800 066 3122 </a>
                            or e-mail us at <a href="mailto:enquiries@theinsolvencyhelpdesk.co.uk">enquiries@theinsolvencyhelpdesk.co.uk</a> </p>

                        <p>Alternatively complete the form here to request a callback</p>

                    </div>

                </div>

                <div className="column">
                    <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">From</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control is-expanded has-icons-left">
                                        <input name="name" class="input" type="text" placeholder="Name" required minlength="4" />
                                        <span class="icon is-small is-left">
                                            <FaUser className="fas fa-user" />
                                        </span>
                                    </p>
                                </div>
                                <div class="field">
                                    <p class="control is-expanded has-icons-left has-icons-right">
                                        <input name="email" class="input not-required" type="email" placeholder="Email" />
                                        <span class="icon is-small is-left">
                                            <FaEnvelope className="fas fa-envelope" />
                                        </span>

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Telephone</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control is-expanded has-icons-left">
                                        <input name="tel" class="input" type="tel" placeholder="Contact Number" required minlength="10" />
                                        <span class="icon is-small is-left">
                                            <FaPhone className="fas fa-user" />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Subject</label>
                            </div>
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select name="subject">
                                                <option>Administration</option>
                                                <option>CVA</option>
                                                <option>Pre-Pack</option>
                                                <option>Liquidation</option>
                                                <option>VAT & Tax</option>
                                                <option>Setting Up</option>
                                                <option>Funding</option>
                                                <option>Redundancy</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label">Message</label>
                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <textarea name="message" class="textarea" placeholder="Explain how we can help you"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label">

                            </div>
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <button type="submit" class="button form-submit" formnovalidate>Submit</button>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>


                </div>

            </div>


        </div>
    </section>
);



export default ContactForm;