import React from 'react';
import {
  FaUser,
  FaPhone
} from 'react-icons/fa';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


class HeaderCallBack extends React.Component {
  state = {
    name: '',
    contactNumber: '',
    formSubmitted: false
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Call Back", ...this.state })
    })
      .then(() => this.setState({ formSubmitted: true }))
      .catch(error => alert(error));

    e.preventDefault();
  };

  mainForm = () => {
    return (
      <React.Fragment>
        <div className="pre-call-back-text">
          <p>Don’t wait until it is <b>too late</b>, if your business is in difficulties, <b>we can help</b>. Fill out the form to request a call back.</p>
        </div>
        <form name="Call Back" onSubmit={this.handleSubmit} data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="call-back" />
          {/* Name */}
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input name="name" onChange={this.handleInputChange} class="input" type="text" placeholder="Your Name" required minlength="4" />
                  <span class="icon is-small is-left">
                    <FaUser className="fas fa-user" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* Phone Number */}
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input name="contactNumber" onChange={this.handleInputChange} class="input" type="tel" placeholder="Contact Number" required minlength="10" />
                  <span class="icon is-small is-left">
                    <FaPhone className="fas fa-user" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <button type="submit" class="button form-submit call-back-submit" formnovalidate>Send</button>


                </div>
              </div>
            </div>
          </div>

        </form>
      </React.Fragment>
    )
  }

  successMessage = () => {
    return (
      <div className="successMessage">
        <h3>Thanks for getting in touch</h3>
        <p>One of our team of experts will call you back very soon.</p><br />
        <p>If your request is urgent you can <b>call us for free</b> on:</p>
        <span className="phoneNumber">0800 066 3122</span>
      </div>
    )
  }


  /// Boxed form control with 2 fields
  // Submission causes just the box element to refresh report success and show some links to visit
  render() {
    console.log(this.state);
    return (
      // Begin form elements
      <div className="call-back-wrapper">
        <div className="call-back-header">
          {this.state.formSubmitted ? <h1>Request Submitted</h1> : <h1>Request a call back</h1>}
        </div>
        <div class="box call-back-form">
          {this.state.formSubmitted ? <this.successMessage /> : <this.mainForm />}
        </div>
      </div>
    )
  }
}
export default HeaderCallBack;